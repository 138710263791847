export const BadgePrintStyle = `@media print {
  @page {
    margin: 5mm;
  }
  .coupon, .guest {
      display: block;
      page-break-before: always;
    }
  hr{
    border: 1px solid black;
  }
}`;
