import 'airbnb-browser-shims';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { blue, green, grey } from '@mui/material/colors';
import { SnackbarProvider } from 'notistack';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserProvider } from '../context/UserContext';
import { useAuth } from 'react-oidc-context';
import Router from './Router';
import '../lib/fontawesomeimport';
import Kiosk from './FastPass/Kiosk';
import RoleCheck from './Authorization/RoleCheck';
import Login from './Login/index';
import Signout from './SignOut';
import { setReturnUrl } from '../providers/authService';
import isPropValid from '@emotion/is-prop-valid';

const theme = createTheme({
  palette: {
    primary: { light: blue[200], main: '#0099cc', dark: blue[800] },
    secondary: { light: green[300], main: green[600], dark: green[900] },
  },
  typography: { fontFamily: '"Nunito", sans-serif', useNextVariants: true },
  components: {
    MuiAppBar: {
      styleOverrides: { colorDefault: { backgroundColor: grey[50] } },
    },
    MuiTabs: {
      styleOverrides: { indicator: { backgroundColor: '#0099cc' } },
    },
    MuiModal: { styleOverrides: { root: { zIndex: 13000 } } },
    MuiSnackbar: { styleOverrides: { root: { zIndex: 14000 } } },
  },
});

const AppContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 3.5em auto;
  grid-template-rows: 3em 1fr;
  grid-template-areas:
    'header header'
    'sidebar-left-top main'
    'sidebar-left-bottom main';
  @include bp-small() {
    grid-template-columns: 30px 100fr 0%;
  }
`;

const SinglePageContainer = styled.div`
  height: 100vh;
  background: white;
`;

export default function App() {
  const auth = useAuth();
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  };

  if (auth.isAuthenticated) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <SnackbarProvider autoHideDuration={8000}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/fastpass"
                    element={
                      <RoleCheck
                        type="fastpass"
                        action="write"
                        yes={() => (
                          <SinglePageContainer>
                            <Kiosk />
                          </SinglePageContainer>
                        )}
                        no={() => <Navigate to="/" replace />}
                      />
                    }
                  />
                  <Route path="/account/signout" element={<Signout />} />
                  <Route
                    path="/*"
                    element={
                      <AppContainer>
                        <Router />
                        <div id="modal" />
                      </AppContainer>
                    }
                  />
                </Routes>
              </SnackbarProvider>
            </StyleSheetManager>
          </UserProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
    setReturnUrl(window.location.pathname);
  }
  return auth.signinRedirect();
}
